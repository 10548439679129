import { Quiz } from "@common/models";
import { useState } from "react";
import { EditableField } from "./EditableField";
import { TrashIcon } from "@heroicons/react/24/outline";
import { escapeLineBreaks, unescapeLineBreaks } from "@/utils/string-utils";

interface QuizEntryProps {
  quiz: Quiz;
  index: number;

  /**
   * when this is used to display quizzes just generated, this should be false.
   * When this is used to display quizzes in a collection, this should be true.
   * When false, the save to collection button will be displayed, and editing the quiz will not update the db or cache.
   * when true, the save to collection button will not be displayed, and editing the quiz will update the db or cache.
   */
  isInCollections?: boolean;
  allowDelete?: boolean;
  onQuizUpdated?: (quiz: Quiz) => Promise<void>;
  onSaveToCollection?: (quiz: Quiz) => Promise<void>;
  onQuizDeleted?: (quizId: string) => Promise<void>;
  compactMode?: boolean;
}

export const QuizEntry: React.FC<QuizEntryProps> = ({
  quiz,
  index,
  isInCollections = true,
  allowDelete = false,
  onQuizUpdated = async () => {},
  onSaveToCollection = async () => {},
  onQuizDeleted = async () => {},
  compactMode = false,
}) => {
  const [question, setQuestion] = useState(unescapeLineBreaks(quiz.question));
  const [answer, setAnswer] = useState(unescapeLineBreaks(quiz.answer));
  const [isSaved, setIsSaved] = useState(false); // State to track if saved

  const handleQuizUpdated = async () => {
    if (isInCollections && (question !== quiz.question || answer !== quiz.answer)) {
      const updatedQuiz: Quiz = {
        ...quiz,
        question: escapeLineBreaks(question),
        answer: escapeLineBreaks(answer),
      };
      await onQuizUpdated(updatedQuiz);
    }
  };

  const handleSaveToCollection = async () => {
    await onSaveToCollection({ ...quiz, question, answer });
    setIsSaved(true);
  };

  const handleDelete = async () => {
    await onQuizDeleted(quiz.id);
  };

  if (compactMode) {
    return (
      <div className="flex gap-2">
        <button
          onClick={handleQuizUpdated}
          className="px-3 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 text-sm">
          Edit
        </button>
        {allowDelete && (
          <button
            onClick={handleDelete}
            className="px-3 py-1 bg-red-100 text-red-600 rounded hover:bg-red-200 text-sm">
            Delete
          </button>
        )}
      </div>
    );
  }

  return (
    <div key={index} className="mb-6 bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <div className="mb-4">
          <EditableField
            label="Question"
            value={question}
            onChange={setQuestion}
            onBlur={handleQuizUpdated}
            className="font-semibold text-lg text-indigo-900"
            isRichText={quiz.isRichText}
          />
        </div>
        <div className="mb-4">
          <EditableField
            label="Answer"
            value={answer}
            onChange={setAnswer}
            onBlur={handleQuizUpdated}
            className="text-gray-700"
            isRichText={quiz.isRichText}
          />
        </div>
      </div>
      <div className="bg-gray-50 px-6 py-3 flex justify-end items-center space-x-2">
        {!isInCollections && (
          <button
            onClick={handleSaveToCollection}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              isSaved
                ? "bg-green-500 text-white cursor-not-allowed"
                : "bg-indigo-600 text-white hover:bg-indigo-700"
            }`}
            disabled={isSaved}>
            {isSaved ? "Saved" : "Save to Collection"}
          </button>
        )}
        {allowDelete && (
          <button
            onClick={handleDelete}
            className="text-red-500 hover:text-red-700 transition-colors"
            title="Delete quiz">
            <TrashIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};
