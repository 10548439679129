import React, { useState } from "react";
import { Quiz } from "@common/models";
import { EditableField } from "./EditableField";
import { escapeLineBreaks, unescapeLineBreaks } from "../utils/string-utils";

interface QuizDetailCardProps {
  quiz: Quiz;
  onQuizUpdated?: (quiz: Quiz) => Promise<void>;
  onQuizDeleted?: (quiz: Quiz) => Promise<void>;
}

export const QuizDetailCard: React.FC<QuizDetailCardProps> = ({
  quiz,
  onQuizUpdated = async () => {},
  onQuizDeleted = async () => {},
}) => {
  const [question, setQuestion] = useState(
    quiz.isRichText ? quiz.question : unescapeLineBreaks(quiz.question)
  );
  const [answer, setAnswer] = useState(
    quiz.isRichText ? quiz.answer : unescapeLineBreaks(quiz.answer)
  );
  const [nextReviewTime, setNextReviewTime] = useState(quiz.nextReviewTime || "");

  const handleQuizUpdated = async () => {
    if (
      question !== quiz.question ||
      answer !== quiz.answer ||
      nextReviewTime !== quiz.nextReviewTime
    ) {
      const updatedQuiz: Quiz = {
        ...quiz,
        question: quiz.isRichText ? question : escapeLineBreaks(question),
        answer: quiz.isRichText ? answer : escapeLineBreaks(answer),
        nextReviewTime,
      };
      await onQuizUpdated(updatedQuiz);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this flashcard?")) {
      await onQuizDeleted(quiz);
    }
  };

  const formatDateForInput = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSetToNow = async () => {
    const now = new Date();
    const newTime = now.toISOString();
    setNextReviewTime(newTime);

    const updatedQuiz: Quiz = {
      ...quiz,
      question: escapeLineBreaks(question),
      answer: escapeLineBreaks(answer),
      nextReviewTime: newTime,
    };
    await onQuizUpdated(updatedQuiz);
  };

  return (
    <div className="space-y-8">
      {/* Primary Information */}
      <div className="space-y-6 border-b border-gray-200 pb-6">
        <div>
          <EditableField
            label="Question"
            value={question}
            onChange={setQuestion}
            onBlur={handleQuizUpdated}
            className="text-xl text-gray-900 leading-relaxed"
            data-testid="detail-question"
            isRichText={quiz.isRichText}
          />
        </div>

        <div>
          <EditableField
            label="Answer"
            value={answer}
            onChange={setAnswer}
            onBlur={handleQuizUpdated}
            className="text-xl text-gray-900 leading-relaxed"
            data-testid="detail-answer"
            isRichText={quiz.isRichText}
          />
        </div>
      </div>

      {/* Secondary Information */}
      <div className="space-y-4 text-sm">
        <div className="grid grid-cols-2 gap-4">
          {quiz.creationTime && (
            <div>
              <h3 className="text-xs font-medium uppercase text-gray-500">Created</h3>
              <p data-testid="detail-creation-time" className="mt-1 text-gray-700">
                {new Date(quiz.creationTime).toLocaleString()}
              </p>
            </div>
          )}
          {quiz.lastReviewedTime && (
            <div>
              <h3 className="text-xs font-medium uppercase text-gray-500">
                Last Reviewed
              </h3>
              <p data-testid="detail-last-reviewed" className="mt-1 text-gray-700">
                {new Date(quiz.lastReviewedTime).toLocaleString()}
              </p>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex items-center justify-between">
              <h3 className="text-xs font-medium uppercase text-gray-500">Next Review</h3>
              <button
                onClick={handleSetToNow}
                className="text-xs text-indigo-600 hover:text-indigo-800"
                data-testid="set-to-now-button">
                Set to now
              </button>
            </div>
            <div className="mt-1 relative">
              <input
                type="datetime-local"
                value={nextReviewTime ? formatDateForInput(nextReviewTime) : ""}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setNextReviewTime("");
                  } else {
                    const date = new Date(e.target.value);
                    setNextReviewTime(date.toISOString());
                  }
                }}
                onBlur={handleQuizUpdated}
                className="block w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                data-testid="detail-next-review-input"
              />
            </div>
          </div>
        </div>

        {(quiz.currentReviewCount !== undefined ||
          quiz.totalReviewCount !== undefined) && (
          <div className="grid grid-cols-2 gap-4 pt-2">
            {quiz.currentReviewCount !== undefined && (
              <div>
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  Current Review Count
                </h3>
                <p
                  data-testid="detail-current-review-count"
                  className="mt-1 text-gray-700">
                  {quiz.currentReviewCount}
                </p>
              </div>
            )}
            {quiz.totalReviewCount !== undefined && (
              <div>
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  Total Reviews
                </h3>
                <p data-testid="detail-total-review-count" className="mt-1 text-gray-700">
                  {quiz.totalReviewCount}
                </p>
              </div>
            )}
          </div>
        )}

        <div className="pt-4 flex justify-end">
          <button
            onClick={handleDelete}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            data-testid="delete-quiz-button">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
