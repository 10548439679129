import { Editor, EditorContent } from "@tiptap/react";
import { Bold, Italic, List, ListOrdered, Code, Quote } from "lucide-react";

interface RichTextEditorProps {
  editor: Editor | null;
  className?: string;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  editor,
  className = "",
}) => {
  if (!editor) {
    return null;
  }

  const MenuButton = ({
    isActive = false,
    onClick,
    children,
  }: {
    isActive?: boolean;
    onClick: () => void;
    children: React.ReactNode;
  }) => (
    <button
      onMouseDown={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={`p-2 rounded hover:bg-gray-100 ${
        isActive ? "bg-gray-100 text-indigo-600" : "text-gray-700"
      }`}>
      {children}
    </button>
  );

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className="flex flex-wrap gap-1 p-2 border-b bg-gray-50">
        <MenuButton
          isActive={editor.isActive("bold")}
          onClick={() => editor.chain().focus().toggleBold().run()}>
          <Bold size={18} />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive("italic")}
          onClick={() => editor.chain().focus().toggleItalic().run()}>
          <Italic size={18} />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive("bulletList")}
          onClick={() => editor.chain().focus().toggleBulletList().run()}>
          <List size={18} />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive("orderedList")}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}>
          <ListOrdered size={18} />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive("code")}
          onClick={() => editor.chain().focus().toggleCode().run()}>
          <Code size={18} />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive("blockquote")}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          <Quote size={18} />
        </MenuButton>
      </div>
      <div className={`p-3 min-h-[4.5rem] bg-gray-50/50 ${className}`}>
        <EditorContent editor={editor} className="prose-sm focus:outline-none" />
      </div>
    </div>
  );
};
