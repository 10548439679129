import { ReviewResult } from "@/pages/ReviewSessionPage";
import { getTimeUntil } from "@/utils/datetime-utils";
import { RegularReviewScheduler } from "@/utils/review-scheduler";
import { Quiz } from "@common/models";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import { unescapeLineBreaks } from "@/utils/string-utils";

interface FlashCardProps {
  quiz: Quiz;
  onReviewed: (quiz: Quiz, reviewResult: ReviewResult) => void;
}

export default function FlashCard({ quiz, onReviewed }: FlashCardProps) {
  const [showAnswer, setShowAnswer] = useState(false);
  const [displayShowAnswerButton, setDisplayShowAnswerButton] = useState(true);
  const [displayReviewButtons, setDisplayReviewButtons] = useState(false);

  const reviewOptions = [
    {
      result: "again",
      label: "Again",
      bgColor: "bg-red-500 hover:bg-red-600",
      textColor: "text-white",
      key: "1",
    },
    {
      result: "hard",
      label: "Hard",
      bgColor: "bg-amber-500 hover:bg-amber-600",
      textColor: "text-white",
      key: "2",
    },
    {
      result: "good",
      label: "Good",
      bgColor: "bg-emerald-500 hover:bg-emerald-600",
      textColor: "text-white",
      key: "3",
    },
    {
      result: "easy",
      label: "Easy",
      bgColor: "bg-blue-500 hover:bg-blue-600",
      textColor: "text-white",
      key: "4",
    },
  ];

  function getNextReviewTimeAsDelta(quiz: Quiz, result: ReviewResult): string {
    const reviewScheduler = new RegularReviewScheduler();
    return getTimeUntil(reviewScheduler.getNextReviewTime(quiz, result));
  }

  const showAnswerFlow = (isShowAnswer: boolean) => {
    setShowAnswer(isShowAnswer);
    setDisplayShowAnswerButton(!isShowAnswer);
    setDisplayReviewButtons(isShowAnswer);
  };

  const handleShowAnswer = () => {
    if (displayShowAnswerButton) {
      showAnswerFlow(true);
    }
  };

  const handleReviewed = (quiz: Quiz, reviewResult: ReviewResult) => {
    if (reviewResult.result === "again") {
      // Reset card state when "again" is selected
      showAnswerFlow(false);
    }
    onReviewed(quiz, reviewResult);
  };

  useEffect(() => {
    // need this hiddenInput to workaround that vimium like plugins hijack the key pres events.
    const hiddenInput = document.createElement("input");
    hiddenInput.style.position = "fixed";
    hiddenInput.style.opacity = "0";
    hiddenInput.style.pointerEvents = "none";
    hiddenInput.style.zIndex = "-1";
    document.body.appendChild(hiddenInput);

    // Keep the hidden input focused
    const focusInterval = setInterval(() => {
      hiddenInput.focus();
    }, 300);

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space" && !event.repeat && displayShowAnswerButton) {
        event.preventDefault(); // Prevent page scrolling
        handleShowAnswer();
      } else if (displayReviewButtons && !event.repeat) {
        const keyPressed = event.key;
        const selectedOption = reviewOptions.find((option) => option.key === keyPressed);

        if (selectedOption) {
          event.preventDefault();
          handleReviewed(quiz, {
            result: selectedOption.result as "again" | "hard" | "good" | "easy",
          });
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    hiddenInput.addEventListener("keydown", handleKeyPress);

    return () => {
      clearInterval(focusInterval);
      document.body.removeChild(hiddenInput);
      window.removeEventListener("keydown", handleKeyPress);
      hiddenInput.removeEventListener("keydown", handleKeyPress);
    };
  }, [displayShowAnswerButton, displayReviewButtons, quiz, onReviewed]);

  useEffect(() => {
    showAnswerFlow(false);
  }, [quiz, quiz.currentReviewCount]);

  return (
    <Card className="max-w-2xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden transition-all duration-300 ease-in-out transform hover:shadow-xl">
      <CardHeader className="bg-indigo-200 text-indigo-800 p-6">
        <h2 className="text-2xl font-bold">Question</h2>
      </CardHeader>
      <CardContent className="p-6">
        <div className="text-lg mb-6">
          <pre className="font-sans whitespace-pre-wrap break-words">
            {unescapeLineBreaks(quiz.question)}
          </pre>
        </div>
        <div
          className={`min-h-[100px] transition-opacity duration-300 ${
            showAnswer ? "opacity-100" : "opacity-0"
          }`}>
          {showAnswer && (
            <>
              <h3 className="text-xl font-bold mb-2 text-indigo-600">Answer</h3>
              <div className="text-lg overflow-auto max-h-80">
                <pre className="font-sans whitespace-pre-wrap break-words">
                  {unescapeLineBreaks(quiz.answer)}
                </pre>
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardFooter className="bg-gray-50 p-6">
        {displayShowAnswerButton && (
          <div className="w-full">
            <Button
              onClick={handleShowAnswer}
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
              Show Answer (Space)
            </Button>
          </div>
        )}
        {displayReviewButtons && (
          <div className="grid grid-cols-2 gap-4 w-full">
            {reviewOptions.map(({ result, label, bgColor, textColor, key }) => (
              <Button
                key={result}
                onClick={() =>
                  handleReviewed(quiz, {
                    result: result as "again" | "hard" | "good" | "easy",
                  })
                }
                className={`w-full font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 ${bgColor} ${textColor}`}>
                {label} ({key})
                <span className="block text-xs mt-1 opacity-80">
                  (
                  {getNextReviewTimeAsDelta(quiz, {
                    result: result as "again" | "hard" | "good" | "easy",
                  })}
                  )
                </span>
              </Button>
            ))}
          </div>
        )}
      </CardFooter>
    </Card>
  );
}
