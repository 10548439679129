import React, { useState, useRef, useEffect, useCallback } from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { RichTextEditor } from "./RichTextEditor";

interface EditableFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  className?: string;
  "data-testid"?: string;
  isRichText?: boolean;
}

export const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  onChange,
  onBlur,
  className = "",
  "data-testid": dataTestId,
  isRichText = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const editor = useEditor(
    {
      extensions: [StarterKit],
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      editable: isEditing,
    },
    [isEditing]
  );

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (!isEditing) return;

    if (isRichText) {
      const isInsideEditor = editorContainerRef.current?.contains(event.target as Node);
      
      const clickedElement = event.target as HTMLElement;
      const isMenuButton = clickedElement.closest('button') !== null;

      if (!isInsideEditor && !isMenuButton) {
        setIsEditing(false);
        onBlur();
      }
    } else {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        onBlur();
      }
    }
  }, [isEditing, isRichText, onBlur]);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("mousedown", handleClickOutside);
    }, 0);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    return () => {
      editor?.destroy();
    };
  }, []);

  useEffect(() => {
    if (editor && isRichText && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor, isRichText]);

  const displayClasses = `cursor-pointer p-3 rounded-md transition-colors ${
    label === "Question"
      ? "bg-indigo-50 text-indigo-900 hover:bg-indigo-100"
      : "bg-gray-50 text-gray-700 hover:bg-gray-100"
  } ${className}`;

  const editClasses =
    "w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 " +
    className;

  if (isEditing) {
    if (isRichText) {
      return (
        <div ref={editorContainerRef} className="border rounded-lg overflow-hidden">
          <div className="border-b border-gray-200 bg-gray-50 px-2 py-1">
            <div className="font-medium text-sm text-gray-700">{label}</div>
          </div>
          <RichTextEditor 
            editor={editor} 
            className="min-h-[150px]"
          />
        </div>
      );
    } else {
      return (
        <textarea
          value={value}
          ref={inputRef}
          onChange={(e) => onChange(e.target.value)}
          className={editClasses}
          onBlur={() => {
            setIsEditing(false);
            onBlur();
          }}
          rows={3}
          data-testid={`${dataTestId}-textarea`}
        />
      );
    }
  }

  return (
    <div
      className={`cursor-pointer p-3 rounded-md transition-colors ${
        isEditing ? "bg-white" : "bg-gray-50 text-gray-700 hover:bg-gray-100"
      } ${className} ${displayClasses}`}
      onClick={() => setIsEditing(true)}
      data-testid={dataTestId}>
      <div className="font-medium mb-1">{label}</div>
      {isRichText ? (
        <div
          dangerouslySetInnerHTML={{ __html: value }}
          className="prose prose-sm max-w-none"
        />
      ) : (
        <pre className="whitespace-pre-wrap overflow-auto">{value}</pre>
      )}
    </div>
  );
};
